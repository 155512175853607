import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"
import React from "react"
import ContattiComponent from "../components/ContattiComponent"
import Layout from "../components/Layout"
import SectionTitle from "../components/SectionTitle"
import SEO from "../components/seo"
import MainImage from "../images/Lunasol-logo.png"
import Blog from "./Blog"
import Servizi from "./Servizi"


global.Intl = require("intl")

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMobileMenu: false }
  }

  toggleMenu() {
    this.setState(prevState => {
      return { showMobileMenu: !prevState.showMobileMenu }
    })
  }

  render() {
    const { data } = this.props
    const { showMobileMenu } = this.state
    const { edges } = data.allMarkdownRemark

    return (
      <Layout
        showMobileMenu={showMobileMenu}
        toggleMenu={this.toggleMenu.bind(this)}
      >
        <SEO />
        <div className="MainImageContainer">
          <img className="MainImage" src={MainImage} alt={``} />
        </div>
        <SectionTitle className={"ChiSiamo"} titleId={`ChiSiamoTitle:label`} />
        <section className={`ChiSiamo`}>
          <FormattedHTMLMessage id="chisiamo-description:label" />
        </section>
        <SectionTitle className={"Servizi"} titleId={`ServiziTitle:label`} />
        <section className={`Servizi`}>
          <Servizi data={data} />
        </section>
        {
          (edges.length > 0) ?
            <>
              <SectionTitle className={"Blog"} titleId={`BlogTitle:label`} />
              <section className={`Blog`}>
                <Blog data={data} />
              </section>
            </>

            : null
        }
        <SectionTitle className={"Contatti"} titleId={`ContattiTitle:label`} />
        <section className={`Contatti`}>
          <ContattiComponent data={data} />
        </section>
      </Layout>
    )
  }
}

export default injectIntl(IndexPage)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            overtitle
            title
            previewLabel
            date
            image
          }
          fields {
            slug
            authorId
          }
          excerpt
        }
      }
    }
  }
`

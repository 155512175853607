import { injectIntl } from "gatsby-plugin-intl"
import React from "react"

const SectionTitle = ({ titleId, intl, className }) => (
  <div className={`SectionTitle-container ${className}`}>
    <h1>{intl.formatMessage({ id: titleId })}</h1>
  </div>
)

export default injectIntl(SectionTitle)

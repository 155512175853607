import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { kebabCase } from "lodash"
import moment from "moment"
import React from "react"
import getHTMLfromMD from "../utils/utils"
import "./Blog.css"


class BlogPage extends React.Component {

  render() {
    const { data, intl, postsLimit } = this.props;

    const edges = (data) ? data.allMarkdownRemark.edges : [];

    const limit = (postsLimit) ? postsLimit : 4

    const today = new Date()
    const filterDate = Date.parse(today);

    var filteredEdges = edges.filter(function (e) {
      const edgeDate = Date.parse(e.node.frontmatter.date);
      return edgeDate <= filterDate
    })

    filteredEdges = filteredEdges.slice(0, limit)








    return (
      <div className="Blog">
        {data &&
          filteredEdges.map(({ node }) => (
            <Link to={node.fields.slug} href={"#"}>

              <div key={node.id} className="blogPost">
                <div className="textContainer">
                  <p className='preview-overtitle'><i>{node.frontmatter.overtitle}</i></p>
                  <h2 dangerouslySetInnerHTML={{ __html: node.frontmatter.title }}></h2>
                  <p dangerouslySetInnerHTML={{ __html: getHTMLfromMD(node.frontmatter.previewLabel) }}></p>

                  <p>{node.excerpt}</p>
                  <p className="otherInfosContainer">
                    {intl.formatMessage({ id: "writtenBy:label" })}{" "}
                    <Link to={`author/${kebabCase(node.fields.authorId)}`}>
                      {intl.formatMessage({ id: `${node.fields.authorId}:label` })}
                    </Link>
                    <br />
                    {moment(node.frontmatter.date).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </Link>

          ))}
        <Link className='goToAllPosts' to={`AllPosts`}>
          <button>{intl.formatMessage({ id: `goToAllPosts:label` })}</button>
        </Link>
      </div>

    )
  }

}


export default injectIntl(BlogPage)
